<template>
  <div>
    <b-modal v-model="isOpen" :title="header" :class="modalClass" @hide="handleClose"> <!-- hide-footer>-->
        <template v-slot:modal-header v-if="!headerCloseButton">
            <!-- Header -->
            <slot name="header"> 
                <!-- Header from props -->
                <div class="header">
                    {{ header }}<font-awesome-icon :class="headerIconClass" :icon="headerIcon" />
                </div>
            </slot>
        </template>
        
        <template v-slot:default>
            <!-- Content title from props -->
            <div class="contentModal" v-if="contentTitle">
                <p class="text-center font-weight-bold header-large">
                    {{ contentTitle }}
                </p>
            </div>

            <!-- Content -->
            <slot>
                <div class="d-block text-center modal-text">
                    <p>
                        {{content}} <!-- Content from props -->
                    </p>
                </div>
            </slot>
        </template>
        <template v-slot:modal-footer>
            <!-- Footer -->
            <slot name="footer">
                <!-- Default footer with OK button that simply close modal -->
                <div class="text-center">
                    <b-button class="btn-option" v-on:click="closeDialog(true)">{{closeButtonText}}</b-button>
                </div>
            </slot>
        </template>
    </b-modal>    
  </div>
</template>


<script>
    export default {
        name: 'Modal',
      props: {
          forceOpen: {
              type: Boolean,
              default: false,
          },
          header: {
              type: String,
              default: '',
          },
          contentTitle: {
              type: String,
              default: '',
          },
          content: {
              type: String,
              default: '',
          },
          variant: {
              type: String,
              default: 'success',
          },
          headerCloseButton: {
              type: Boolean,
              default: false,
          },
          closeButtonText: {
              type: String,
              default: 'OK',
          },
          /*contentClasses: {
              type: String,
              default: '',
          },
          centered: {
              type: Boolean,
              default: false,
          },
          autoCloseTimer: {
              type: Number,
              default: null,
          },*/
      },
      data() {
          return {
              isOpen: false,
              //buttonSuccessColor: '#ff2534',
          };
      },
      computed: {
        modalClass: function() {
          return this.variant === 'error' ? 'error-modal-header' 
               : this.variant === 'warn' ? 'warn-modal-header' 
               : 'success-modal-header'
        },
        headerIconClass: function() {
          return this.variant === 'error' ? 'msg-fail-icon' 
               : this.variant === 'warn' ? 'msg-warn-icon' 
               : 'msg-success-icon'
        },
        headerIcon: function() {
          return this.variant === 'error' ? 'times-circle' 
               : this.variant === 'warn' ? 'exclamation-circle' 
               : 'check-circle'
        },

      },
      watch: {
        forceOpen(value) {
            this.isOpen = value;
        },
      }, 
      methods: {
        closeDialog(status) {
            this.isOpen = false;

            this.$emit('close', status);
        },
        handleClose(bvModalEvt) {
            if(bvModalEvt.trigger === 'backdrop' || bvModalEvt.trigger === 'esc' || bvModalEvt.trigger === 'headerclose') {
                bvModalEvt.preventDefault();
            }
            this.closeDialog(true);
        }
      },       
    };
</script>

<style scoped>
    .modal-header.success-modal-header {
        background-color: #3fc59d;
        justify-content: center;
        padding: 40px 0;
    }

    .modal-footer.success-modal-footer {
        justify-content: center;
    }

    .modal-body, .modal-text {
        font-family: 'Barlow-Regular', Arial, sans-serif;
    }

    .modal-header.error-modal-header {
        background-color: #c53f51;
        justify-content: center;
        padding: 40px 0;
    }

    .modal-body .header-large {
        font-size: 28px;
    }

    .header .msg-success-icon, .header .msg-fail-icon, .header .msg-warn-icon {
        margin-left: auto;
        order: 2;
        color: #fff;
        font-size: 48px;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center; /* align text vertical */
    }

/*
.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 200px;
  z-index: 1000;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.modal-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; 
  display: flex;
}
.modal-button {
  flex-grow: 1;
}*/
</style>