<template>
    <div class="signout text-center">
        <b-container>
            <h1>You have successfully signed out.</h1>
            <router-link v-bind:to="`/Auth`">Login in Again</router-link>
        </b-container>
    </div>
</template>


<script>
export default { name: 'SignedOut' };
</script>

<style>
    .signout {
        min-height: 600px;
    }
</style>
