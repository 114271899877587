<template>
    <div id="journal-ticket" class="newJournalTicket">
        <b-container>
            <h1 v-if="!disableRequestorFields && !$route.query.id" class="mb-1">New Journal Ticket</h1>
            <div v-if="!disableRequestorFields && !$route.query.id" class="text-danger">
                <small>**If you are submitting more than one journal ticket request for month-end, please submit them at least 4 days in advance.**</small>
            </div>
            <div class="request-header" v-if="(sendBacktoRequestor || disableRequestorFields)">
                <h1 v-if="form.jt_type_code === 'JT'">Journal Ticket {{form.jt_number}}</h1>
                <h1 v-if="form.jt_type_code === 'SDA'">SDA {{form.jt_number}}</h1>
                <b-button class="btn-yellow btn-save-changes btn-save-pdf" v-if="$route.query.id" v-on:click="saveToPdf()">
                    Save to PDF
                </b-button>
            </div>

            <div>
                <p v-if="currentStatus" class="small"><em>Current status: {{currentStatus}}</em></p>
            </div>
            
            <b-form @submit.prevent="onSubmit">
                <div v-bind:class="{ requestorSection: disableRequestorFields }">
                    <h2 v-bind:class="{ 'pt-0': !disableRequestorFields && !$route.query.id }">Request</h2>

                    <b-form-group 
                        label="What type of request is this?"
                        :class="{ 'form-group--error': $v.form.jt_type_code.$error }"
                    >
                        <div class="error small" v-if="$v.form.jt_type_code.$error && !$v.form.jt_type_code.required">Request type is required</div>
                        <b-form-radio v-model="$v.form.jt_type_code.$model" name="request-type-radios" value="JT" :checked="form.jt_type_code == 'JT'" :disabled="disableRequestorFields">Journal Ticket</b-form-radio>
                        <b-form-radio v-model="$v.form.jt_type_code.$model" name="request-type-radios" value="SDA" :checked="form.jt_type_code == 'SDA'" :disabled="disableRequestorFields">SDA</b-form-radio>
                    </b-form-group>

                    <b-form-group 
                        id="sda-approval-number" 
                        label="*Approval Number:" 
                        label-for="sda-approval-number" 
                        :class="{ 'form-group--error': $v.form.sda_approval_number.$error }"
                        v-if="form.jt_type_code === 'SDA'"
                    >
                        <b-form-input
                            id="sda-approval-number"
                            v-model="$v.form.sda_approval_number.$model"
                            :disabled="disableRequestorFields"
                            :maxlength="20"
                        ></b-form-input>
                        <div class="error small" v-if="$v.form.sda_approval_number.$error && !$v.form.sda_approval_number.required">Approval number is required</div>
                    </b-form-group>

                    <b-form-group 
                        id="sda-invoice-ref-number" 
                        label="SDA Invoice/Reference Number :" 
                        label-for="sda-invoice-ref-number"
                        v-if="form.jt_type_code === 'SDA'"
                    >
                        <b-form-input
                            id="sda-invoice-ref-number"
                            v-model="form.sda_invoice_ref_number"
                            :disabled="disableRequestorFields"
                            :maxlength="20"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group 
                        label="What type of payment is this?"
                        :class="{ 'form-group--error': $v.form.payment_type.$error }"
                    >
                        <div class="error small" v-if="$v.form.payment_type.$error && !$v.form.payment_type.required">Payment type is required</div>
                        <b-form-radio v-model="$v.form.payment_type.$model" name="payment-type-radios" value="Debit" :checked="form.payment_type == 'Debit'" :disabled="disableRequestorFields">Debit</b-form-radio>
                        <b-form-radio v-model="$v.form.payment_type.$model" name="payment-type-radios" value="Credit" :checked="form.payment_type == 'Credit'" :disabled="disableRequestorFields">Credit</b-form-radio>
                    </b-form-group>

                    <label for="customer-nonsig" v-if="form.payment_type === ''">Nonsig of Customer Receiving Credit/Debit:</label>
                    <label for="customer-nonsig" v-if="form.payment_type === 'Credit'">Nonsig of Customer Receiving Credit:</label>
                    <label for="customer-nonsig" v-if="form.payment_type === 'Debit'">Nonsig of Customer Receiving Debit:</label>
                    <b-input-group id="customer-nonsig-group">
                        <b-form-input
                            id="customer-nonsig"
                            v-model="form.nonsig_number"
                            type="number"
                            :disabled="disableRequestorFields"
                            pattern="/^-?\d+\.?\d*$/"
                            :formatter="formatCustomerNonsig"
                        ></b-form-input>
                        <b-input-group-append>
                            <!--<b-button type="submit" class="btn-yellow btn-rebate-search" :disabled="disableRequestorFields">Search</b-button>-->
                            <b-button type="button" class="btn-yellow btn-rebate-search" v-on:click="getCustomerInfo()" :disabled="disableRequestorFields">
                                Search
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>

                    <b-overlay :show="searchLoading" rounded="sm" class="table-sap-data-overlay">
                        <b-table-simple small responsive stacked striped class="table-sap-data">
                            <b-tbody>
                                <b-tr>
                                    <b-td :stacked-heading="creditDebitNonsigNameLabel">
                                        <b-form-input
                                            id="nonsig-name"
                                            v-model="form.nonsig_name"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td :stacked-heading="creditDebitNonsigAddressLabel">
                                        <b-form-input
                                            id="nonsig-name"
                                            :value=creditDebitNonsigAddress
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    <b-td :stacked-heading="creditDebitCommonOwnerLabel">
                                        <b-form-input
                                            id="nonsig-name"
                                            v-model="form.common_owner_number"
                                            :disabled=true
                                        ></b-form-input>
                                    </b-td>
                                    
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </b-overlay>
                    <br>

                    <b-form-group
                        id="input-group-title"
                        :label="titleLabel"
                        label-for="title"
                        :class="{ 'form-group--error': $v.form.title.$error }"
                    >
                        <b-form-input
                            id="title"
                            v-model="$v.form.title.$model"
                            :disabled="disableRequestorFields"
                        ></b-form-input>
                        <div class="error small" v-if="$v.form.title.$error && !$v.form.title.required">Title is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-payment-amount"
                        :label="creditDebitAmountLabel"
                        label-for="payment-amount"
                        :class="{ 'form-group--error': $v.form.payment_amount.$error }"
                    >
                        <b-form-input
                            id="payment-amount"
                            v-model="$v.form.payment_amount.$model"
                            :disabled="disableRequestorFields"
                            type="number"
                            step="any"
                        ></b-form-input>
                        <div class="error small" v-if="$v.form.payment_amount.$error && !$v.form.payment_amount.required">Payment amount is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-credit-memo"
                        :label="creditDebitMemoTextLabel"
                        label-for="credit-memo"
                    >
                        <b-form-textarea
                            id="credit-memo"
                            v-model="form.payment_memo"
                            :disabled="disableRequestorFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-form-group
                        id="input-group-requestor-additional-notes"
                        label="*Additional Information/Business Justification:"
                        label-for="requestor-additional-notes"
                        :class="{ 'form-group--error': $v.form.requestor_additional_notes.$error }"
                    >
                        <b-form-textarea
                            id="requestor-additional-notes"
                            v-model="$v.form.requestor_additional_notes.$model"
                            :disabled="disableRequestorFields"
                            rows="3"
                            max-rows="6"
                            :maxlength="500"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.requestor_additional_notes.$error && !$v.form.requestor_additional_notes.required">Additional Information/Business Justification is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-accounting-info"
                        label="*Accounting Info (e.g. GL Account, Cost/Profit Center, etc.):"
                        label-for="credit-memo"
                        :class="{ 'form-group--error': $v.form.accounting_info.$error }"
                    >
                        <b-form-textarea
                            id="accounting-info"
                            v-model="$v.form.accounting_info.$model"
                            :disabled="disableRequestorFields"
                            rows="3"
                            max-rows="6"
                            :maxlength="280"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.accounting_info.$error && !$v.form.accounting_info.required">Accounting Info is required</div>
                    </b-form-group>

                    <b-form-group 
                        id="input-group-material-number" 
                        label="Material Numbers:" 
                        label-for="material-number"
                    >
                        <b-form-input
                            id="material-number"
                            v-model="form.material_number"
                            :disabled="disableRequestorFields"
                            :maxlength="70"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-documentation"
                        label="Attach Supporting Documentation (optional)"
                        label-for="supporting-documentation"
                    >
                        <div class="small"><em>To upload more than 1 file, select both files together at the same time while browsing</em></div>
                        <b-form-file
                            v-model="form.file"
                            :disabled="disableRequestorFields"
                            ref="file-input" 
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            multiple
                        ></b-form-file>
                        <div v-if="form.file && form.file.length > 0">
                            <a @click="clearFile1" class="clear-files" tabindex="0">
                            <font-awesome-icon icon="minus-circle" /> Remove attachment(s)</a>
                        </div> 
                    </b-form-group>
                    <b-form-group>
                        <b-list-group>
                        <b-list-group-item
                            button
                            href="#"
                            v-for="item in items"
                            v-bind:key="item.key"
                            v-on:click="onFileClick(item)"
                        >
                            <div>
                            <small>{{ item.key }}</small>
                            </div>
                        </b-list-group-item>
                        </b-list-group> 
                    </b-form-group>

                    <p v-if="disableRequestorFields" class="small"><em>{{requestorStatus}}</em></p>

                </div>

                <div class="recall-button" v-if="this.requestInfo.requestor_user_id === this.userSession.userid && this.requestInfo.form_state != 100 && currentStatus !== 'Complete'">
                    <b-button class="btn-yellow btn-save-changes" v-on:click="recallRequest()">
                        <div class="spinner-border spinner-border-sm" v-if="recallLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Recall Request
                    </b-button>
                </div>

                <div v-if="disableRequestorFields || sendBacktoRequestor" v-bind:class="{ approverSection: disableApproverFields }">
                    <h2>Level 1 Approver</h2>
                    <b-form-group
                        :class="{ 'form-group--error': $v.form.jt_approver_status.$error }"
                    >
                        <b-form-radio v-model="$v.form.jt_approver_status.$model" name="jt_approver_status" value="Approve" :disabled="disableApproverFields">Approve</b-form-radio>
                        <b-form-radio v-model="$v.form.jt_approver_status.$model" name="jt_approver_status" value="Deny" :disabled="disableApproverFields">Deny</b-form-radio>
                        <b-form-radio v-model="$v.form.jt_approver_status.$model" name="jt_approver_status" value="SendBack" :disabled="disableApproverFields">Send Back to Requestor</b-form-radio>
                        <div class="error small" v-if="$v.form.jt_approver_status.$error && !$v.form.jt_approver_status.required">Action type is required</div>
                    </b-form-group>

                    <hr>
                    
                    <b-form-group
                        id="input-group-jt-reason"
                        label="*Reason for Denying:"
                        label-for="jt-reason"
                        v-if="form.jt_approver_status == 'Deny'"
                        :class="{ 'form-group--error': $v.form.jt_reason.$error }"
                    >
                        <b-form-textarea
                            id="jt-reason"
                            v-model="$v.form.jt_reason.$model"
                            :disabled="disableApproverFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.jt_reason.$error && !$v.form.jt_reason.required">Reason for Denying is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-jt-reason"
                        label="*Reason for Sending Back to Requestor:"
                        label-for="jt-reason"
                        v-if="form.jt_approver_status == 'SendBack'"
                        :class="{ 'form-group--error': $v.form.jt_reason.$error }"
                    >
                        <b-form-textarea
                            id="jt-reason"
                            v-model="$v.form.jt_reason.$model"
                            :disabled="disableApproverFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.jt_reason.$error && !$v.form.jt_reason.required">Reason for Sending Back to Requestor is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-approver-additional-notes"
                        label="Additional Notes from Approver:"
                        label-for="approver-additional-notes"
                    >
                        <b-form-textarea
                            id="approver-additional-notes"
                            v-model="form.jt_additional_notes"
                            :disabled="disableApproverFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                    <div>
                        <p class="small"><em>{{approverStatus}}</em></p>
                    </div>
                </div>

                <div v-if="requestId" class="gbsSection">
                    <b-form-group
                        id="input-group-invoice-document"
                        label="Invoice Document:"
                        label-for="invoice-document"
                    >
                        <b-form-input
                            id="invoice-number"
                            v-model="form.invoiceDocument"
                            :disabled="((disableGBSFields || (!disableGBSFields && hideGBSFields)) && disableApproverFields) || sendBacktoRequestor"
                        ></b-form-input>
                    </b-form-group>
                </div>

                <div v-if="!hideGBSFields" class="gbsSection" v-bind:class="{ approverSection: disableGBSFields }">

                    <h2>Level 2 Approver</h2>

                    <b-form-group 
                        id="input-group-sap-number" 
                        label="SAP Number:" 
                        label-for="sap-number"
                    >
                        <b-form-input
                            id="sap-number"
                            v-model="form.sap_number"
                            :disabled="disableGBSFields"
                            :maxlength="10"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group 
                        id="input-group-invoice-number" 
                        label="Invoice Number:" 
                        label-for="invoice-number"
                    >
                        <b-form-input
                            id="invoice-number"
                            v-model="form.invoice_number"
                            :disabled="disableGBSFields"
                            :maxlength="10"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group 
                        id="input-group-date" 
                        label="Date:" 
                        label-for="date"
                    >
                        <b-form-datepicker
                            id="date"
                            v-model="form.jt_date"
                            :disabled="disableGBSFields"
                        ></b-form-datepicker>
                    </b-form-group>

                    <b-form-group
                        id="input-group-gbs-notes"
                        label="Additional Notes"
                        label-for="additional-gbs-notes"
                    >
                        <b-form-textarea
                        id="additional-gbs-notes"
                        v-model="form.gbs_additional_notes"
                        :disabled="disableGBSFields"
                        rows="3"
                        max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-form-group
                        :class="{ 'form-group--error': $v.form.gbs_status.$error }"
                    >
                        <b-form-radio v-model="$v.form.gbs_status.$model" name="gbs_status" value="Approve" :disabled="disableGBSFields">Approve</b-form-radio>
                        <b-form-radio v-model="$v.form.gbs_status.$model" name="gbs_status" value="Deny" :disabled="disableGBSFields">Deny</b-form-radio>
                        <b-form-radio v-model="$v.form.gbs_status.$model" name="gbs_status" value="SendBack" :disabled="disableGBSFields">Send Back to Requestor</b-form-radio>
                        <div class="error small" v-if="$v.form.gbs_status.$error && !$v.form.gbs_status.required">Action type is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-gbs-reason"
                        label="*Reason for Denying:"
                        label-for="gbs-reason"
                        v-if="form.gbs_status == 'Deny'"
                        :class="{ 'form-group--error': $v.form.gbs_reason.$error }"
                    >
                        <b-form-textarea
                            id="gbs-reason"
                            v-model="$v.form.gbs_reason.$model"
                            :disabled="disableGBSFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.gbs_reason.$error && !$v.form.gbs_reason.required">Reason for Denying is required</div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-gbs-reason"
                        label="*Reason for Sending Back to Requestor:"
                        label-for="gbs-reason"
                        v-if="form.gbs_status == 'SendBack'"
                        :class="{ 'form-group--error': $v.form.gbs_reason.$error }"
                    >
                        <b-form-textarea
                            id="gbs-reason"
                            v-model="$v.form.gbs_reason.$model"
                            :disabled="disableGBSFields"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <div class="error small" v-if="$v.form.gbs_reason.$error && !$v.form.gbs_reason.required">Reason for Sending Back is required</div>
                    </b-form-group>

                    <div>
                        <p class="small"><em>{{gbsStatus}}</em></p>
                    </div>

                </div>
                <div class="btn-actions-container">
                    <b-button type="submit" class="btn-yellow" v-if="!sendBacktoRequestor && !disableRequestorFields && !$route.query.id">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit Request
                    </b-button>
                    <b-button type="submit" class="btn-yellow" v-if="sendBacktoRequestor">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Resubmit Request
                    </b-button>
                    <b-button class="btn-yellow" v-if="!sendBacktoRequestor && disableRequestorFields && !disableApproverFields" v-on:click="approverSubmit">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit
                    </b-button>
                    <b-button class="btn-yellow btn-save-changes" v-if="!sendBacktoRequestor && disableRequestorFields && !disableApproverFields" v-on:click="saveChanges('approver')">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="saveChangesLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Save Changes
                    </b-button>
                    <b-button class="btn-yellow" v-if="!sendBacktoRequestor && disableApproverFields && !hideGBSFields && !disableGBSFields" v-on:click="gbsSubmit">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Submit
                    </b-button>
                    <b-button class="btn-yellow btn-save-changes" v-if="!sendBacktoRequestor && disableApproverFields && !hideGBSFields && !disableGBSFields" v-on:click="saveChanges('gbs')">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="saveChangesLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Save Changes
                    </b-button>
                    <b-button class="btn-yellow btn-save-changes btn-attachments" v-if="$route.query.id && items.length > 0" v-on:click="downloadAllAttachments()">
                        <div class="spinner-border spinner-border-sm" role="status"  v-if="downloadAttachmentsLoading">
                            <span class="sr-only">Loading...</span>
                        </div>
                        Download All Attachments
                    </b-button>
                </div>
                <div class="mt-3" v-if="saveChangesSuccess">
                    Additional Notes and Invoice Document have been saved successfully.
                </div>
            </b-form>

            <!-- Verify modal -->
            <Modal
                :force-open="modalVerify"
                header="Please Verify"
                :headerCloseButton="true"
                @close="onCloseModalVerify"
            >
                <!-- Default content slot -->
                
                <div class="d-block text-center modal-text">
                    <p>
                        Rebate agreement 'valid to' date is {{form.validToDate}} please validate payment was not to be marked 'final'.
                    </p>
                </div>
                

                <template v-slot:footer>
                    <div class="text-center">
                        <b-button class="btn-yellow m-3" @click="onCloseModalVerify">Cancel & Modify</b-button>
                        <b-button class="btn-option" v-on:click="onSubmitConfirm">
                            <div class="spinner-border spinner-border-sm" role="status" v-if="loading">
                                <span class="sr-only">Loading...</span>
                            </div>
                            Submit Form
                        </b-button>
                    </div>
                </template>
            </Modal>

            <!-- Success modal -->
            <Modal
                :force-open="modalSuccess"
                header="Journal Ticket submit"
                variant="success"
                contentTitle="Success!"
                @close="onCloseModalSuccess"
            >
                <!-- Default content slot -->
                <div class="d-block text-center modal-text">
                    <p>
                        Journal Ticket {{successId}} was successfully submitted.
                    </p>
                </div>

                <template v-slot:footer>
                    <div class="text-center">
                        <b-button class="btn-option" v-on:click="btnOkSuccess">Ok</b-button>
                    </div>
                </template>
            </Modal>

            <!-- Notifications modal -->
            <Modal
                :force-open="modalNotifications"
                header="New Journal Ticket"
                :content="content"
                :variant="modalVariant"
                @close="onCloseNotifications"
            />

        </b-container>
    </div>
</template>

<script>
  import { required, requiredIf } from 'vuelidate/lib/validators'
  import Modal from './Modal'
  import html2pdf from "html2pdf.js";
  import { downloadAndZipFiles } from '../lib/zip';

  export default {
    components: { Modal }, 
    data() {
        return {
            form: {
                request_id: '',
                jt_number: '',
                jt_type_code: '',
                sda_approval_number: '',
                sda_invoice_ref_number: '',
                payment_type: '',
                nonsig_number: '',
                nonsig_name: '',
                nonsig_street_address: '',
                nonsig_city: '',
                nonsig_state : '',
                nonsig_zip: '',
                common_owner_number: '',
                title: '',
                payment_amount: '',
                payment_memo: '',
                requestor_additional_notes: '',
                accounting_info: '',
                material_number: '',
                file: null,
                jt_approver_status: '',
                jt_reason: '',
                jt_additional_notes: '',
                sap_number: '',
                invoice_number: '',
                jt_date: '',
                gbs_additional_notes: '',
                gbs_status: '',
                gbs_reason: '',
                invoiceDocument: ''
            },
            boxOne: '',
            show: [],
            disableForm: false,
            formValues: {},
            sendBacktoRequestor: false,
            disableRequestorFields: false,
            disableApproverFields: false,
            disableGBSFields: false,
            hideGBSFields: true, 
            requestorStatus: '',
            approverStatus: '',
            gbsStatus: '',
            items: [],
            loading: false,
            saveChangesLoading: false,
            downloadAttachmentsLoading: false,
            saveChangesSuccess: false,
            searchLoading: false,
            successId: '',
            modalVerify: false,
            modalSuccess: false,
            modalNotifications: false,
            content: '',
            modalVariant: 'success',
            isRebatePBUValidInSAP: false,
            request_type: 'JT',
            requestInfo: {},
            customerInfo: {},
            requestId: null,
            currentStatus: null,
            recallLoading: false,
        }
    },
    watch: {
        'form.jt_additional_notes': function () {
            this.saveChangesSuccess = false;
        },
        'form.gbs_additional_notes': function () {
            this.saveChangesSuccess = false;
        },
    },
    validations: {
        form: {
            jt_type_code: { required },
            sda_approval_number: { required: requiredIf(function(form){
                    return form.jt_type_code === 'SDA';
                }) 
            },
            payment_type: { required },
            title: { required },
            payment_amount: { required },
            requestor_additional_notes: { required },
            accounting_info: { required },
            jt_approver_status: { required: requiredIf(function(){
                    return !this.disableApproverFields && (this.disableRequestorFields || this.sendBacktoRequestor);
                }) 
            },
            jt_reason: { required: requiredIf(function(form){
                    return form.jt_approver_status === 'SendBack' || form.jt_approver_status === 'Deny';
                }) 
            },
            gbs_status: { required: requiredIf(function(){
                    return !this.disableGBSFields && this.disableRequestorFields 
                                && this.disableApproverFields && !this.hideGBSFields;
                }) 
            },
            gbs_reason: { required: requiredIf(function(form){
                    return form.gbs_status === 'SendBack' || form.gbs_status === 'Deny';
                }) 
            },
        }
    },
    methods: {
      showMsgBoxOne() {
        this.boxOne = ''
        this.$bvModal.msgBoxOk('Action completed', {
            title: 'Confirmation',
        })
          .then(value => {
              console.log(value);
            this.boxOne = value
          })
          .catch(err => {
            // An error occurred
          })
      },
      //as a requestor, when the form submits
      onSubmit(evt) {
        if (this.validateForm()) {
            console.log('passed validation')
            this.onSubmitConfirm(evt);
        }
      },
      onSubmitConfirm(evt) {
          console.log('in onSubmitConfirm')
          
          if (this.validateForm()) {
            this.loading = true;
            this.submitRequest()
                .then(id => {
                    this.modalVerify = false;
                    if (id) {
                        console.log('id: ' + id);
                        this.loading = false;
                        this.successId = id;
                        this.modalSuccess = true;
                    }
                    else {
                        console.log('no id returned: ' + id);
                        this.loading = false;
                        const h = this.$createElement
                        const titleVNode = 
                            h('div', { class: [''] }, [
                                h('font-awesome-icon', { 
                                    class: ['msg-fail-icon'],
                                    props: {
                                        icon: 'times',
                                        
                                    }
                                })
                            ])
                        const messageVNode = h('div', { class: ['foobar'] }, [
                            h('p', { class: ['text-center font-weight-bold header-large'] }, [
                                'Oh no!',
                            ]),
                            h('p', { class: ['text-center modal-text'] }, [
                                'Something went wrong while attempting to submit this request.',
                            ]),
                        ])
                        this.$bvModal.msgBoxOk([messageVNode], {
                            title: [titleVNode],
                            buttonSize: 'lg',
                            centered: true,
                            okVariant: 'yellow',
                            headerClass: 'error-modal-header',
                            footerClass: 'success-modal-footer'
                        })
                    }
                })
                .catch(error => {
                    console.log(' in try catch' + error);
                    this.loading = false;
                    const h = this.$createElement
                        const titleVNode = 
                            h('div', { class: [''] }, [
                                h('font-awesome-icon', { 
                                    class: ['msg-fail-icon'],
                                    props: {
                                        icon: 'times',
                                        
                                    }
                                })
                            ])
                        const messageVNode = h('div', { class: ['foobar'] }, [
                            h('div', { class: ['text-center font-weight-bold fail-large'] }, [
                                'Oh no!',
                            ]),
                            h('p', { class: ['text-center modal-text'] }, [
                                'Something went wrong while attempting to submit this request: ',
                            ]),
                            h('p', { class: ['text-center modal-text'] }, [
                                error.message,
                            ]),
                        ])
                        this.$bvModal.msgBoxOk([messageVNode], {
                            title: [titleVNode],
                            buttonSize: 'lg',
                            centered: true,
                            okVariant: 'yellow',
                            headerClass: 'success-modal-header',
                            footerClass: 'success-modal-footer'
                        })
                });
            evt.preventDefault()
            //alert(JSON.stringify(this.form))
        }
      },
      onCloseModalVerify() {
        this.modalVerify = false;
      }, 
      onCloseModalSuccess() {
        this.modalSuccess = false;
      }, 
      onCloseNotifications() {
        this.modalNotifications = false;
      },
      submitRequest(){
          if (this.sendBacktoRequestor) { return this.resubmitRequest()}
          else {return this.submitNewRequest()}
      },
      async resubmitRequest() {
        let params = this.getRequestData();
        let id;
        params.action = 'SBMT';
        try {

            if(params.nonsig_number !== this.customerInfo.nonsig_number)
                throw new Error('Nonsig Number and Customer Info mismatch. Please perform customer search again.');

            id = params.request_id;
            console.log('In resubmitRequest ',params);
            await this.$store.dispatch('tickets/update', params);        
        } catch(error){
            return Promise.reject( error );                
        }

        if (id) { 
            if (this.form.file && this.form.file.length) {
                let files = this.form.file;
                for (let i = 0; i < files.length; i += 1) {
                    const file = files[i];
                    let params = {
                        file: file,
                        filename: id + '/' + file.name,
                        filetype: file.type,
                        metadata: {requestid: id.toString()},
                    };
                    try {
                        const result = await this.$store.dispatch('storage/putFile', params);
                    } catch (e) {    
                        return Promise.reject( e );
                    }
                }        
            }
        }
        return Promise.resolve( id );
      },
      async submitNewRequest() {
          
        const data = this.getRequestData();
        console.log('submitNewRequest ', data);
        try {

            if(data.nonsig_number !== this.customerInfo.nonsig_number)
                throw new Error('Nonsig Number and Customer Info mismatch. Please perform customer search again.');

            var id = await this.$store.dispatch('tickets/create', data);
        } catch(error){
            return Promise.reject( error );                
        }

        if (id) { 
            if (this.form.file && this.form.file.length) {
                let files = this.form.file;
                for (let i = 0; i < files.length; i += 1) {
                    const file = files[i];
                    let params = {
                        file: file,
                        filename: id + '/' + file.name,
                        filetype: file.type,
                        metadata: {requestid: id.toString()},
                    };
                    try {
                        const result = await this.$store.dispatch('storage/putFile', params);
                    } catch (e) {    
                        return Promise.reject( e );
                    }
                }        
            }
        }
        return Promise.resolve( id );
      },
      //called on form submit by requestor
      getRequestData() {
        return {
            request_type: this.request_type,
            jt_type_code: this.form.jt_type_code,
            sda_approval_number: this.form.sda_approval_number,
            sda_invoice_ref_number: this.form.sda_invoice_ref_number,
            payment_type: this.form.payment_type,
            nonsig_number: this.form.nonsig_number,
            nonsig_name: this.form.nonsig_name,
            nonsig_street_address: this.form.nonsig_street_address,
            nonsig_city: this.form.nonsig_city,
            nonsig_state: this.form.nonsig_state,
            nonsig_zip: this.form.nonsig_zip,
            common_owner_number: this.form.common_owner_number,
            title: this.form.title,
            payment_amount: this.form.payment_amount,
            payment_memo: this.form.payment_memo,
            additional_notes: this.form.requestor_additional_notes,
            accounting_info: this.form.accounting_info,
            material_number: this.form.material_number,
            sap_number: this.form.sap_number,
            invoice_number: this.form.invoice_number,
            jt_date: this.form.jt_date,
            request_id: this.requestInfo.request_id,
            action: '',
            reason: '',
            invoice_document: this.form.invoiceDocument,
        }
      },
      //called on page load to check if existing form exists
      getRequestInfo() {
        let queryId = this.$route.query.id;
        let id = parseFloat(queryId);
        this.requestId = id;
        let userRole = this.$store.state.auth.userJTRoleType;
        let params = {request_id: id, filters: { request_type: this.request_type }};
        this.$store
            .dispatch('tickets/getInfo', params)
            .then(info => {
                this.requestInfo = info;
                console.log('getRequestInfo: ' + JSON.stringify(this.requestInfo));

                //if the form is sent back to the requestor step
                if (this.requestInfo.form_state == 100) {
                    this.disableApproverFields = true;

                    if (this.requestInfo.requestor_user_id === this.userSession.userid || userRole === 'admin') {
                        this.sendBacktoRequestor = true;
                    } else {
                        this.sendBacktoRequestor = false;
                        this.disableRequestorFields = true;
                    }

                    if (this.requestInfo.gbs_form_action) {
                        this.hideGBSFields = false;
                        this.disableGBSFields = true;
                    }
                }
                //if the form is at the approver step
                if (this.requestInfo.form_state == 201) {
                    //disable requestor form fields
                    this.disableRequestorFields = true;

                    // User role check
                    if ( !(userRole === 'admin' || userRole === 'jt_approver1') ){
                        this.disableApproverFields = true;
                    }

                    if (this.requestInfo.gbs_form_action) {
                        this.hideGBSFields = false;
                        this.disableGBSFields = true;
                    }
                }
                //if the form is at the GBS approver step
                if (this.requestInfo.form_state == 300) {
                    //disable requestor & approver form fields
                    this.disableRequestorFields = true;
                    this.disableApproverFields = true;
                    this.hideGBSFields = false;

                    // User role check
                    if ( !(userRole === 'admin' || userRole === 'jt_approver2') ){
                        this.disableGBSFields = true;
                    }
                }

                // if the form has been denied by JT approver
                if (this.requestInfo.jt_approver_action_code === 'DENY') {
                    console.log('this should be status denied');
                    this.disableRequestorFields = true;
                    this.disableApproverFields = true;

                    if (this.requestInfo.gbs_form_action) {
                        this.hideGBSFields = false;
                        this.disableGBSFields = true;
                    }
                }

                //if the form has been completed or denied by GBS approver
                if (this.requestInfo.status_code == 999 || this.requestInfo.gbs_action_code === 'DENY') {
                    //disable all form fields
                    this.disableRequestorFields = true;
                    this.disableApproverFields = true;
                    this.disableGBSFields = true;
                    this.hideGBSFields = false;
                }

                console.log('this.disableRequestorFields ' + this.disableRequestorFields + 'this.disableApproverFields' + this.disableApproverFields + 'this.disableGBSFields' + this.disableGBSFields + 'this.sendBacktoRequestor' + this.sendBacktoRequestor);
                console.log('formState: ' + this.requestInfo.form_state);
                console.log('formValues: ' + JSON.stringify(this.formValues));

                this.form.request_id = this.requestInfo.request_id;
                this.form.jt_number = this.requestInfo.jt_number;
                this.form.jt_type_code = this.requestInfo.jt_type_code;
                this.form.sda_approval_number = this.requestInfo.sda_approval_number;
                this.form.sda_invoice_ref_number = this.requestInfo.sda_invoice_ref_number;
                this.form.payment_type = this.requestInfo.payment_type;
                this.form.nonsig_number = this.requestInfo.nonsig_number;
                this.form.nonsig_name = this.requestInfo.nonsig_name;
                this.form.nonsig_street_address = this.requestInfo.nonsig_street_address;
                this.form.nonsig_city = this.requestInfo.nonsig_city;
                this.form.nonsig_state = this.requestInfo.nonsig_state;
                this.form.nonsig_zip = this.requestInfo.nonsig_zip;
                this.form.common_owner_number = this.requestInfo.common_owner_number;
                this.form.title = this.requestInfo.title;
                this.form.payment_amount = this.requestInfo.payment_amount;
                this.form.payment_memo = this.requestInfo.payment_memo;
                this.form.requestor_additional_notes = this.requestInfo.requestor_additional_notes;
                this.form.accounting_info = this.requestInfo.accounting_info;
                this.form.material_number = this.requestInfo.material_numbers;
                this.form.file = null;
                this.form.jt_approver_status = this.requestInfo.jt_approver_form_action;
                this.form.jt_reason = this.requestInfo.jt_approver_reason;
                this.form.jt_additional_notes = this.requestInfo.jt_approver_additional_notes;
                this.form.sap_number = this.requestInfo.sap_number;
                this.form.invoice_number = this.requestInfo.invoice_number;
                this.form.jt_date = this.requestInfo.jt_date;
                this.form.gbs_additional_notes =  this.requestInfo.gbs_additional_notes;
                this.form.gbs_status = this.requestInfo.gbs_form_action;
                this.form.gbs_reason = this.requestInfo.gbs_reason;
                this.requestorStatus = this.requestInfo.requestor_status;
                this.approverStatus = this.requestInfo.jt_approver_status;
                this.gbsStatus = this.requestInfo.gbs_status;
                this.form.invoiceDocument = this.requestInfo.invoice_document;

                this.currentStatus = this.requestInfo.current_status;

                this.customerInfo.nonsig_number = this.requestInfo.nonsig_number;

                // Additional notes
                if (this.requestInfo.form_state == 201) {
                    if (!this.requestInfo.historyRecord && this.requestInfo.additionalNotesRecord) {
                        this.form.jt_additional_notes = this.requestInfo.additionalNotesRecord.additional_notes;
                    }
                }
                if (this.requestInfo.form_state == 300) {
                    if (!this.requestInfo.historyRecord && this.requestInfo.additionalNotesRecord) {
                        this.form.gbs_additional_notes = this.requestInfo.additionalNotesRecord.additional_notes;
                    }
                }

            })
            //.catch(error => console.error(`Can't get rebate data with id: ${id}, error: ${JSON.stringify(error)}`));
            .catch(error => {
                console.log(error)
                this.content = error.message;
                this.modalVariant = 'error';
                this.modalNotifications = true;
            });
        params = {path: id};  
        this.$store
            .dispatch('storage/getList', params)
            .then(files => {
                console.log(files);
                this.items = files;
            })
            .catch(error => {
                console.log(error);
            });    

       },
       onFileClick(download) {
            const { key } = download;

            if (!key) {
                console.log('Can`t download document, need path to be specified');
                return null;
            }

            const params = {path: key};
            return this.$store
                .dispatch('storage/getFile', params)
                .then(result => window.open(result, '_blank'))
                .catch(error => {
                    console.log(error);
                    this.content = error.message;
                    this.modalVariant = 'error';
                    this.modalNotifications = true;
                });   
      },
      recallRequest: function (event) {
        this.recallLoading = true;
        let params = this.getRequestData();  
        params.action = 'RCLL';

        if (this.requestInfo.form_state == 201) {
            params.additional_notes = this.form.jt_additional_notes;
        }

        if (this.requestInfo.form_state == 300) {
            params.additional_notes = this.form.gbs_additional_notes;
        }

        console.log('request params', params);
        
        this.$store
            .dispatch('tickets/update', params)
            .then(() => this.$router.push({ name: 'My Journal Tickets' }))
            .catch(error => {
                console.log(error);
                this.recallLoading = false;
                this.content = error.message;
                this.modalVariant = 'error';
                this.modalNotifications = true;
            });
        
      },
      approverSubmit: function (event) {
        
        if(this.validateForm()){
            this.loading = true;
            let params = this.getRequestData();  
            console.log(params)
            switch(this.form.jt_approver_status) {
            case 'Approve':
                params.action = 'APRV';
                break;
            case 'Deny':
                params.action = 'DENY';
                params.reason = this.form.jt_reason;
                break;
            case 'SendBack':
                params.action = 'RRTE';
                params.reason = this.form.jt_reason;
                break;
            default:
                // code block
            };
            //call API to submit approval fields and send to next step
            console.log('jt ', this.form.jt_additional_notes);
            params.additional_notes = this.form.jt_additional_notes;
            console.log('request params', params);
            this.$store
                .dispatch('tickets/update', params)
                .then(() => this.$router.push({ name: 'My Journal Tickets' }))
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                    this.content = error.message;
                    this.modalVariant = 'error';
                    this.modalNotifications = true;
                });
        }
        
      },      
      gbsSubmit: function (event) {
        console.log('in gbs submit')
        if(this.validateForm()){
            this.loading = true;
            let params = this.getRequestData();  
            switch(this.form.gbs_status) {
            case 'Approve':
                params.action = 'APRV';
                break;
            case 'Deny':
                params.action = 'DENY';
                params.reason = this.form.gbs_reason;
                break;
            case 'SendBack':
                params.action = 'RRTE';
                params.reason = this.form.gbs_reason;
                break;
            default:
                // code block
            };
            //call API to submit approval fields and send to next step
            params.additional_notes = this.form.gbs_additional_notes;
            console.log('request params', params);
            this.$store
                .dispatch('tickets/update', params)
                .then(() => this.$router.push({ name: 'My Journal Tickets' }))
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                    this.content = error.message;
                    this.modalVariant = 'error';
                    this.modalNotifications = true;
            });
        }
        
      },

      saveChanges: function (type) {

        const params = {
            request_id: this.requestInfo.request_id,
            form_state: this.requestInfo.form_state,
            invoice_document: this.form.invoiceDocument,
            request_type: this.request_type,
        }

        if (this.requestInfo.historyRecord) {
            params.history_id = this.requestInfo.historyRecord.history_id;
        }

        if (this.requestInfo.additionalNotesRecord) {
            params.additional_notes_id = this.requestInfo.additionalNotesRecord.additional_notes_id;
        }

        switch(type) {
            case 'approver':
                params.additional_notes = this.form.jt_additional_notes;
                break;
            case 'gbs':
                params.additional_notes = this.form.gbs_additional_notes;
                break;
        };

        this.saveChangesLoading = true;
        this.$store
            .dispatch('tickets/saveChanges', params)
            .then(() => {
                this.saveChangesLoading = false;
                this.saveChangesSuccess = true;
            })
            .catch(error => {
                console.log(error);
                this.saveChangesLoading = false;
                this.content = error.message;
                this.modalVariant = 'error';
                this.modalNotifications = true;
        });
      },

      saveToPdf: function() {
        html2pdf(document.getElementById("journal-ticket"), {
            margin: 1,
            filename: `${this.form.jt_number}.pdf`,
            pagebreak: { mode: 'avoid-all' },
            html2canvas: {
                ignoreElements: (element) => {return element.tagName.toLowerCase() === 'button' && !element.classList.contains('list-group-item')}
            }
		});
      },

      downloadAllAttachments: async function() {
        this.downloadAttachmentsLoading = true;

        let files = await Promise.all(this.items.map( async (item) => {
            return await this.$store.dispatch('storage/getFile', { path: item.key });
        }))

        await downloadAndZipFiles(files, this.form.jt_number);

        this.downloadAttachmentsLoading = false;
      },

      clearFile1() {
        this.$refs['file-input'].reset();
      },  
      btnOkSuccess() {
        console.log('in btnOkSuccess');
        //this.$root.$emit('bv::hide::modal', 'modalVerify', '#btnShow')
        this.$router.push({path: '/my-journal-tickets'});
      },
      resetComponentData(){
        this.form = {
            request_id: '',
            jt_type_code: '',
            sda_approval_number: '',
            sda_invoice_ref_number: '',
            payment_type: '',
            nonsig_name: '',
            nonsig_street_address: '',
            nonsig_city: '',
            nonsig_state : '',
            nonsig_zip: '',
            common_owner_number: '',
            title: '',
            payment_amount: '',
            payment_memo: '',
            requestor_additional_notes: '',
            accounting_info: '',
            material_number: '',
            file: null,
            jt_approver_status: '',
            jt_reason: '',
            jt_additional_notes: '',
            sap_number: '',
            invoice_number: '',
            jt_date: '',
            gbs_additional_notes: '',
            gbs_status: '',
            gbs_reason: '',
            invoiceDocument: '',
        };
        this.customerInfo = {};
        this.boxOne = '';
        this.show = [];
        this.disableForm = false;
        this.formValues = {};
        this.sendBacktoRequestor = false;
        this.disableRequestorFields = false;
        this.disableApproverFields = false;
        this.disableGBSFields = false;
        this.hideGBSFields = true; 
        this.requestorStatus = '';
        this.approverStatus = '';
        this.gbsStatus = '';
        this.items = [];
        this.loading = false;
        this.searchLoading = false;
        this.successId = '';
        this.modalVerify = false;
        this.modalSuccess = false;
        this.modalNotifications = false;
        this.content = '';
        this.modalVariant = 'success';
        this.requestId = null;

        // Reset validation
        this.$nextTick(() => { this.$v.$reset() })
      },

      getCustomerInfo: function () {
        //show search spinner
        this.searchLoading = true;
        //call API to get the customer information from SAP web services
        let id = Number(this.form.nonsig_number);
        this.$store
            .dispatch('sap/getInfoNonsig', { id })
            .then(info => {
                if (info.status === '0') {
                    this.customerInfo = info;
                    this.customerInfo.nonsig_number = this.form.nonsig_number;
                    this.form.nonsig_name = this.customerInfo.nonsig_name;
                    this.form.nonsig_street_address = this.customerInfo.nonsig_street_address;
                    this.form.nonsig_city = this.customerInfo.nonsig_city;
                    this.form.nonsig_state = this.customerInfo.nonsig_state;
                    this.form.nonsig_zip = this.customerInfo.nonsig_zip;
                    this.form.common_owner_number = this.customerInfo.common_owner_number;

                } else {
                    this.content = info.message;
                    this.modalVariant = 'warn';
                    this.modalNotifications = true;
                }

                //hide search spinner
                this.searchLoading = false;
            })    
            .catch(error => {
                //hide search spinner
                this.searchLoading = false;
                console.log(error);
                this.content = error.message;
                this.modalVariant = 'error';
                this.modalNotifications = true;
            });
      },  
      formatCustomerNonsig(e){
        // Max 10 digits
        return String(e).substring(0,10);
      },
      validateForm() {
        // check for validation errors
        this.$v.form.$touch()
        // if validation fails
        if (this.$v.form.$invalid) {
            // focus on input with error
            for (let key in Object.keys(this.$v.form)) {
                const input = Object.keys(this.$v.form)[key];
                if (input.includes("$")) return false;
                // if input is invalid and has ref
                if (this.$v.form[input].$error && this.$refs[input]) {
                    this.$refs[input].focus();
                    break;
                }
            }
            return false;
        }
        return true;
      },
    },
    mounted: function () {

        if (this.$route.query.id) {
            this.getRequestInfo();
            return;
        } 

        // Handle reset-new-rebate-data event from eventBus
        this.$eventBus.$on('reset-new-journal-ticket-data', this.resetComponentData);

        // Force form reset after mount (to prevent Vue keeping component data after routing)
        this.resetComponentData();
        
    },
    computed: {
        userSession() {
            return this.$store.getters['auth/userSession'];
        },
        creditDebitNonsigNameLabel: function () {
            return this.form.payment_type ? `Name of Customer Receiving ${this.form.payment_type}` : 'Name of Customer Receiving Credit/Debit';
        },
        creditDebitNonsigAddressLabel: function () {
            return this.form.payment_type ? `Address of Customer Receiving ${this.form.payment_type}` : 'Address of Customer Receiving Credit/Debit';
        },
        creditDebitCommonOwnerLabel: function () {
            return this.form.payment_type ? `Common Owner of Customer Receiving ${this.form.payment_type}` : 'Common Owner of Customer Receiving Credit/Debit';
        },
        creditDebitNonsigAddress: function () {
            return this.form.nonsig_street_address ? `${this.form.nonsig_street_address}, ${this.form.nonsig_city}, ${this.form.nonsig_state} ${this.form.nonsig_zip}` : '';
        },
        creditDebitAmountLabel: function () {
            return this.form.payment_type ? `*Amount of ${this.form.payment_type}:` : '*Amount of Credit/Debit';
        },
        creditDebitMemoTextLabel: function () {
            return this.form.payment_type ? `${this.form.payment_type} Memo Text:` : 'Credit/Debit Memo Text:';
        },
        titleLabel: function () {
            return this.form.jt_type_code ? `*${this.form.jt_type_code} Title:` : '*JT/SDA Title';
        },
    },
    beforeDestroy(){
        // Disable reset-new-rebate-data event handler
        this.$eventBus.$off('reset-new-journal-ticket-data');
    },
  }
</script>

<style scoped>

    ::v-deep .modal-header.success-modal-header {
        background-color: #3fc59d;
        justify-content: center;
        padding: 40px 0;
    }

    ::v-deep .modal-footer.success-modal-footer {
        justify-content: center;
    }

    ::v-deep .modal-body {
        font-family: 'Barlow-Regular', Arial, sans-serif;
    }

    ::v-deep .modal-header.error-modal-header {
        background-color: #c53f51;
        justify-content: center;
        padding: 40px 0;
    }

    .modal-body .header-large {
        font-size: 28px;
    }

    .msg-success-icon {
        color: #fff;
        font-size: 48px;
    } 

    h2 {
        padding: 10px 0;
    }
    .newJournalTicket {
        margin-bottom: 40px;
    }
    .newJournalTicket .container {
        max-width: 800px;
    }

    .table-sap-data {
        margin-top: 10px;
        max-width: 550px;
    }

    .table-sap-data-overlay {
        max-width: 550px;
    }

    .rebate-details {
        font-size: 12px;
        margin-top: 10px;
    }

    .newJournalTicket legend {
        font-weight: 700;
    }

    .custom-radio label {
        font-weight: normal;
    }

    .selected-file-text {
        font-size: 12px;
    }

    #customer-nonsig-group .form-control {
        max-width: 210px;
        margin-right: 20px;
        margin-top: 2px;
    }

    .clear-files {
        color: #ff2534;
        font-size: 12px;
    }

    .clear-files:hover {
        color: #ff2534;
        cursor: pointer;
        text-decoration: underline;
    }

    .btn-actions-container {
        display: flex;
        margin-top: 20px;
    }

    .btn-actions-container .btn-yellow {
        margin: 0 10px; 
    }

    .btn-actions-container .btn-yellow:first-child {
        margin: 0;
    }

    .requestorSection, .approverSection {
        background-color: #dbdfe440;
        padding: 0 20px 20px;
    }

    .approverSection, .gbsSection {
        margin-top: 25px;
    }
    
    .form-group--error > label {
        color: red
    }

    .form-group--error input {
        border: 1px solid red;
    } 

    .form-group--error .error.small {
        color: red;
    }

    .validation-error {
        color: red;
    }

    .request-header {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .request-header h1 {
        padding-top: 0px;
    }

    .btn-actions-container .btn-attachments {
        margin-right: 0px;
        margin-left: auto;
    }

    .recall-button {
        margin: 20px 0 10px 0;
    }

</style>