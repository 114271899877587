<template>
    <div class="home">
        <b-container>
            <h1>Business Operations</h1>
            <div>
                <b-card-group deck>
                    <b-card class="g3x">
                        <a href="https://go.goodyear.com/g3xnominateadmin/index.cfm?action=40nomination" target="_blank">
                            <b-card-title>
                                <div class="icon icon-g3x"><font-awesome-icon icon="certificate" /></div><span class="title-text">G3X Nomination</span>
                            </b-card-title>
                            <b-card-text>
                                Submit or approve nominations for G3X dealers.
                            </b-card-text>
                        </a>
                    </b-card>

                     <b-card class="ciu">
                        <a href="https://go.goodyear.com/ciuform" target="_blank">
                            <b-card-title>
                                <div class="icon icon-ciu"><font-awesome-icon icon="user-edit" /></div><span class="title-text">CIU Portal</span>
                            </b-card-title>
                            <b-card-text>
                                Create or update customer information.
                            </b-card-text>
                        </a>
                    </b-card>

                    <b-card class="rebates">
                        <b-link to="my-rebates">
                            <b-card-title>
                                <div class="icon icon-rebates"><font-awesome-icon icon="dollar-sign" /></div><span class="title-text">Rebate Payment</span>
                            </b-card-title>
                            <b-card-text>
                                Create and submit Type 1 payments for approval.
                            </b-card-text>    
                        </b-link>
                    </b-card>

                    <b-card class="journaltix">
                        <b-link to="my-journal-tickets">
                            <b-card-title>
                                <div class="icon icon-journaltix"><font-awesome-icon icon="file-invoice-dollar" /></div><span class="title-text">Journal Tickets</span>
                            </b-card-title>
                            <b-card-text>
                                Create and submit Journal Tickets and SDAs for approval.
                            </b-card-text>    
                        </b-link>
                    </b-card>

                    <b-card class="salesops">
                        <a href="https://goodyearcorp.sharepoint.com/sites/NASales/Operations/SitePages/Home.aspx" target="_blank">
                            <b-card-title>
                                <div class="icon icon-salesops"><font-awesome-icon icon="cogs" /></div><span class="title-text">Sales & Operations</span>
                            </b-card-title>
                            <b-card-text>
                                Create or update the customer information.
                            </b-card-text>
                        </a>
                    </b-card>
                </b-card-group>
            </div>
        </b-container>
    </div>
</template>

<script>
	export default {
		name: 'Home',
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .home .container {
        min-height: 737px;
    }

	h1 {
		margin: 20px 0 30px 0;
	}
	.card-deck {
		margin-bottom: 20px;
	}

	.card-title .title-text {
        font-family: 'Barlow-Bold', Arial, sans-serif;
		font-size: 1.1rem;
	}

	.card.ciu a {
		background-color: #1a263c;
		color: #fff;
	}

    /*.card a:hover
    {
        box-shadow: inset 0 0 0 10px #dbdfe4;
        text-decoration: none;
    }*/

    .card a:hover {
        box-shadow: 1px 1px #dbdfe4, 2px 2px #dbdfe4, 3px 3px #dbdfe4;
        -webkit-transform: translateX(-3px);
        transform: translateX(-3px);
        text-decoration: none;
    }

	.card.g3x a {
		background-color: #004990;
		color: #fff; 
	}

	.card.rebates a {
		background-color: #06d;
		color: #fff;
	}

	.card.journaltix a {
		background-color: #274ba7;
	}

	.card.salesops a {
		background-color: #d9e9fd;
	}
    /*removing padding from .card-body command adding stop .card a*/
    .card-body {
        padding: 0;
    }

    .card a {
        color: #fff;
        display: block;
        font-family: 'Barlow-Regular', Arial, sans-serif;
        min-height: 220px;
        padding: 1.25rem;
    }

    .card.salesops a {
		color: #333;    
    }

	.icon {
		margin-bottom: 10px;
	}

	.icon-ciu {
		width: 50px;
		height: 50px;
		background-color: #fff;
		color: #1a263c;
		border-radius: 25px;
		text-align: center;
		padding-top: 9px;
		padding-left: 5px;
	}

	.icon-g3x {
		width: 50px;
		height: 50px;
		background-color: #fff;
		color: #004990;
		border-radius: 25px;
		text-align: center;
		padding-top: 10px;
	}

	.icon-rebates {
		width: 50px;
		height: 50px;
		background-color: #fff;
		color: #06d;
		border-radius: 25px;
		text-align: center;
		padding-top: 10px;
        padding-left: 1px;
	}

	.icon-journaltix {
		width: 50px;
		height: 50px;
		background-color: #fff;
		color: #274ba7;
		border-radius: 25px;
		text-align: center;
		padding-top: 9px;
		padding-left: 2px;
	}

	.icon-salesops {
		width: 50px;
		height: 50px;
		background-color: #fff;
		color: #d9e9fd;
		border-radius: 25px;
		text-align: center;
		padding-top: 9px;
		padding-left: 2px;
    }

</style>
